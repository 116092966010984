<template>
  <div class="">
    <SimplePage
      section="acquisitions"
      :pre_title="$t('Adquisiciones')"
      :title="$t('Oportunidad <br> de Adquisiciones')"
      :button_text="$t('Enviar Mensaje')"
      @buttonClicked="toggleFormModal()"
    ></SimplePage>
    <Modal :is_open="form_modal_open"> 
      <app-section :title="$t('Adquisiciones')" :bootom_blue_line="true"></app-section>
      <div class="row justify-content-center mt-5">
        <div class="col-9">
          <Form 
              form_type="acquisitions"
              :watch_change_prop="form_modal_open"
              >
          </Form>
        </div>
      </div>
    </Modal>
  </div>
</template>


<script>
import SimplePage from "@/components/modules/SimplePage";
import Modal from "@/components/modules/Modal";
import Form from "@/components/forms/Form";
import AppSection from "@/components/AppSection";
export default {
  name: "Adquisitions",
  components: {
    SimplePage,
    Modal,
    Form,
    AppSection
  },
  data: function(){
    return {
      form_modal_open: false
    }
  },
  methods: {
    toggleFormModal(){
      this.form_modal_open = !this.form_modal_open;
    }
  }
}
</script>

<!--<<<<<<< HEAD-->

<!--<style lang="scss">-->
<!--@import "src/assets/styles/colors";-->
<!--.bg-adquisiciones{-->
<!--  background: url('~@/assets/img/bg-adquisiciones.jpg')no-repeat top right ;-->
<!--  background-size: contain;-->
<!--  min-height: 25vw;-->
<!--  padding-bottom: 3em;-->
<!--}-->
<!--.bg-form{-->
<!--  background: url('~@/assets/img/form-bg.jpg')no-repeat bottom center ;-->
<!--  background-size: cover;-->
<!--  height: 25vw;-->
<!--}-->
<!--=======-->
<style scoped lang="scss">
//>>>>>>> frontendModifications
</style>